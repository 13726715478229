import { createRouter, createWebHashHistory } from 'vue-router';
import About from '../views/About.vue';
import Contact from '../views/Contact.vue';
import Research from '../views/Research.vue';

const routes = [
  {
    path: '/',
    name: 'About',
    component: About
  },
  {
    path: '/research',
    name: 'Research',
    component: Research
  },
  {
    path: '/contact',
    name: 'Contact',
    component: Contact
  }
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
});

export default router
