<template>
	<div id="app">
		<!-- Header -->
		<NavBar></NavBar>
		
		<!-- component matched by the route will render here -->
		<router-view class="component"></router-view>

		<!-- Footer -->
		<Footer></Footer>
	</div>
</template>

<script>
import NavBar from '@/components/NavBar.vue';
import Footer from '@/components/Footer.vue';

export default {
	components: {
		NavBar,
		Footer
	},
	data() {
	}	
}
</script>

<style lang="scss">
@import 'scss/custom.scss';

.component {
	padding-bottom: 4rem;
}

.page-title {
	margin: 1rem !important;
}

</style>
