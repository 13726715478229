<template>
	<div>
		<div class="d-flex justify-content-center">
			<h2 class="text-center page-title display-5">Papers</h2>
		</div>
		<div class="d-flex flex-column">
			<div v-for="paper of papers" :key="paper.url" class="col-12 p-0">
				<div class="card papers shadow" @click="navigateTo(paper.url)">
					<div class="card-body">
						<h4 class="fw-bold">{{ paper.title }}</h4>
						<p class="authors">{{ 'with ' + paper.authors }}</p>
						<p>{{ paper.date }}</p>
						<hr>
						<p class="m-0">{{ paper.abstract }}</p>
					</div>
				</div>
			</div>
		</div>
		<div class="d-flex justify-content-center">
			<h2 class="text-center page-title display-5">Working Papers</h2>
		</div>
		<div class="d-flex flex-column">
			<div v-for="paper of workingPapers" :key="paper.url" class="col-12 p-0">
				<div class="card papers shadow" @click="navigateTo(paper.url)">
					<div class="card-body">
						<h4 class="fw-bold">{{ paper.title }}</h4>
						<p class="authors">{{ 'with ' + paper.authors }}</p>
						<p>{{ paper.date }}</p>
						<hr>
						<p class="m-0">{{ paper.abstract }}</p>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import papers from "../store/data/papers.json";
import workingPapers from "../store/data/workingPapers.json";
export default {
    data() {
		return {
			workingPapers: workingPapers,
			papers: papers
		}
    },
	methods: {
		navigateTo(url) {
			window.open(url);
		}
	}
}
</script>

<style lang="scss">

.papers:hover {
	cursor: pointer;
	background: #f7f7f7 !important;
	border: 1px solid #0d6efd;
	box-sizing: border-box;
}

.authors {
	font-style: italic;
	margin-bottom: .5rem;
}

</style>