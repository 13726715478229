<template>
    <div>
        <div class="d-flex justify-content-center">
            <h2 class="text-center page-title display-5">Contact Me</h2>
        </div>
        <div class="d-flex flex-column justify-content-center text-container mb-4">
            <p>Please feel free to contact me at <strong>gaby.lohner@berkeley.edu</strong> or via any of the social media links below. 
            If you are interested in grad school and have any questions, please don't hesitate to reach out!</p>
            <p>Please enjoy this picture of my dog loving life in the East Bay &#128578;</p>
            <img class="img-fluid" :src="lilySunset" alt="Lily on the beach at sunset.">
        </div>
    </div>
</template>

<script>
import lilySunset from "../assets/lily-sunset.jpg";

export default {
    data() {
		return {
			lilySunset: lilySunset
		}
    }
}
</script>

<style lang="scss">

.img-fluid {
	max-height: 600px !important;
	display: block;
	margin: auto;
}

</style>
