<template>
	<div class="bg-light nav-container sticky-top">
		<div class="d-flex justify-content-center name-line">
			<h1 class="display-1 text-center m-2">Gaby Lohner</h1>
		</div>
		<nav class="navbar navbar-expand-lg">
			<div class="container justify-content-center">
				<button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNavAltMarkup" aria-controls="navbarNavAltMarkup" aria-expanded="false" aria-label="Toggle navigation">
					<h3 class="m-0 menu">Menu</h3>
				</button>
				<div class="collapse navbar-collapse justify-content-center" id="navbarNavAltMarkup">
					<div class="navbar-nav">
						<router-link v-for="page of pages"
							:key="page.name"
							class="nav-link text-center"
							active-class="active"
							:to="page.path">
							<h4 class="menu">{{ page.name }}</h4>
						</router-link>
					</div>
				</div>
			</div>
		</nav>
	</div>
</template>

<script>
export default {
	data() {
		return {
			pages: [
				{ name: "About", path: "/" },
				{ name: "Research", path: "/research" },
				{ name: "Contact", path: "/contact" }
			]
		}
	}
}
</script>

<style lang="scss">

.nav-container {
	border-bottom: 2px solid #eee;
}

.name-line {
	border-bottom: 2px solid #ddd;
}

.navbar-toggler, .navbar-toggler:focus {
	border: none !important;
	box-shadow: none !important;
}

.menu {
	color: var(--bs-gray-600);
	margin: 0 1rem;
}

.active > * {
	color: var(--bs-gray-800) !important;
	text-decoration: underline;
}

</style>