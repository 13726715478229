<template>
	<div>
		<div class="d-flex justify-content-center">
			<h2 class="text-center page-title display-5">About Me</h2>
		</div>
		<div class="d-flex justify-content-center mx-5">
			<img :src="gabyHeadshot" class="rounded img-fluid shadow headshot" alt="Gaby">
		</div>
		<div class="d-flex flex-column text-container">
			<h3 class="text-center my-3">Hi there!</h3>
			<p class="bio">My name is Gaby Lohner, and I am a PhD student 
				in Public Policy at UC Berkeley. I was previously 
				a research assistant at the Nudge4 Solutions Lab 
				at the University of Virginia. My research interests 
				center around poverty and policy analysis, specifically 
				how education and social welfare policy affect 
				low-income families.
			</p>
			<p class="text-dark">You can find my CV <a :href="cv" target="_blank" class="">here</a>.</p>
		</div>
	</div>
</template>

<script>
import gabyHeadshot from "../assets/headshot.png";

export default {
    data() {
		return {
			cv: require('@/assets/gaby-lohner-cv.pdf'),
			gabyHeadshot: gabyHeadshot
		}
    }
}
</script>

<style lang="scss">

.even-columns {
	display: flex !important;
}

.even-columns > * {
	width: 100%;
	padding: 0 3rem;
}

.headshot {
	width: 100%;
	max-width: 500px !important;
	display: block;
	margin: auto;
}

.text-container {
	margin: 0 3rem;
}

.bio {
	line-height: 1.75;
}

</style>
